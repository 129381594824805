<script>
export default {
  render() {
    return this.$scopedSlots.default({
      isSelected: this.isSelected,
      select: this.select
    })
  },

  props: {
    index: Number,
    item: Object,
    activeItems: Object,
    itemKey: {
      required: true
    }
  },

  data: () => ({

  }),

  methods: {
    select() {
      if(this.isSelected) return this.$emit('unselected', this.item)
      return this.$emit('selected', this.item)
    }
  },

  computed: {
    isSelected() {
      if(this.item[this.itemKey] in this.activeItems) return true
      return false
    }
  }
  
}
</script>