const EMIT_SORT_BY = 'update:sort-by';
const EMIT_SORT_DESC = 'update:sort-desc';

export default {
  methods: {
    sort(value) {
      if(!this.sortBy.length) return this.startSorting(value);
      let sortingIndex = this.sortBy.findIndex(by => by === value);
      if(sortingIndex > -1) this.updateSorting(value, sortingIndex);
      else this.startSorting(value);
    },

    startSorting(value) {
      this.$emit(EMIT_SORT_BY, [value]);
      this.$emit(EMIT_SORT_DESC, [false]);
    },

    updateSorting(value, index) {
      let _sortDesc = [...this.sortDesc];
      if(!this.sortDesc[index]) {
        _sortDesc[index] = true;
        return this.$emit(EMIT_SORT_DESC, _sortDesc);
      }
      let _sortBy = [...this.sortBy];
      _sortBy.splice(index, 1);
      _sortDesc.splice(index, 1);
      this.$emit(EMIT_SORT_BY, _sortBy);
      this.$emit(EMIT_SORT_DESC, _sortDesc);
    }
  }
};
