<template lang="pug">
div(:class="{'app-table_loading': loading, 'disabled': disabled}")
  slot(name="top")
  .v-data-table__wrapper
    .preloader(v-if="loading")
      v-progress-linear(:indeterminate="true")
    table
      slot(name="header" :headers="_headers" :props="headerProps" :on="on")
        thead.v-data-table-header
          tr
            th(
              v-for="(header, index) in _headers"
              :key="index"
              :class=`[
                  header.class, header.sortable ? 'sortable' : '',
                  headerProps.options.sortBy && header.value === headerProps.options.sortBy[0] ? 'active sortable' : '',
                  headerProps.options.sortBy && headerProps.options.sortDesc[0] ? 'desc': 'asc'
                ]`
            )
              template(v-if="index === 0 && showSelect")
                span.show-select
                  v-checkbox(
                    :value="headerProps.everyItem"
                    hide-details
                    :disabled="disabledSelect"
                    @change="toggleSelectAll"
                    :ripple="false"
                    small
                  ).mt-0.pt-0
              template(v-if="header.sortable")
                span(@click="on.sort(header.value)") {{ header.text }}
                <svg @click="on.sort(header.value)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18px" width="18px" role="img" aria-hidden="true" class="v-icon notranslate v-data-table-header__icon v-icon--svg theme--light" style="font-size: 18px; height: 18px; width: 18px;"><path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"></path></svg>
              template(v-else)
                span {{ header.text }}
      slot(name="bottom")
        tbody(v-if="grouped")
          template(v-for="group in groupedList")
            tr.group-row
              td.cell--select
                group-table-select(
                  v-slot="groupSelectProps"
                  :itemKey="itemKey"
                  :activeItems="activeItems"
                  :groupedItems="group.items"
                  :items="group.items"
                  @selected="$emit('input', $event)"
                  @unselected="$emit('input', $event)"
                )
                  v-checkbox(
                    :value="groupSelectProps.isSelected"
                    :key="group.label"
                    :disabled="!group.items.length"
                    hide-details
                    @change="groupSelectProps.select"
                  ).mt-0.journey-select

              td(:colspan="_headers.length - 1")
                slot(name="group" :group="group")
                  template {{ group.label }}
            template(v-for="(item, index) in group.items")
              expanded(v-slot="expanded" :key="item[itemKey]" :itemKey="itemKey" :expandedItems="expandedItems" :expand="expand" :index="index" :item="item")
                table-select(
                  v-slot="selectProps"
                  :itemKey="itemKey"
                  :activeItems="activeItems"
                  :index="index"
                  :item="item"
                  @selected="selectItem"
                  @unselected="unselectItem"
                )
                  div.contents
                    slot(
                      name="item"
                      :item="item"
                      :isSelected="selectProps.isSelected"
                      :index="index"
                      :expand="expanded.expand"
                      :isExpanded="expanded.isExpanded"
                      :select="selectProps.select"
                    )
                    tr(v-if="expanded.isExpanded")
                      slot(name="expanded-item" :headers="_headers" :item="item" :expand="expanded.expand" :isExpanded="expanded.isExpanded")
            tr(v-if="!group.items.length")
              td(:colspan="_headers.length").no-data-available No data available
        tbody(v-else)
          template(v-for="(item, index) in items")
            expanded(
              v-slot="expandedProps"
              :key="item[itemKey]"
              :itemKey="itemKey"
              :expandedItems="expandedItems"
              :expand="expand"
              :index="index"
              :item="item"
            )
              table-select(
                v-slot="selectProps"
                :itemKey="itemKey"
                :activeItems="activeItems"
                :index="index"
                :item="item"
                :expand="expand"
                @selected="selectItem"
                @unselected="unselectItem"
              )
                .contents
                  slot(
                    name="item"
                    :item="item"
                    :isSelected="selectProps.isSelected"
                    :index="index"
                    :expand="expandedProps.expand"
                    :isExpanded="expandedProps.isExpanded"
                    :select="selectProps.select"
                  )
                  tr(v-if="expandedProps.isExpanded")
                    slot(
                      name="expanded-item"
                      :headers="_headers"
                      :item="item"
                      :expand="expandedProps.expand"
                      :isExpanded="expandedProps.isExpanded"
                    )
          tr(v-if="!items.length && !loading")
            td(:colspan="_headers.length").no-data-available No data available

  div.text-center.mt-12(v-if="loading")
    v-progress-circular(indeterminate size=34 color="warning")
  slot(name="footer")
</template>

<script>
import Expanded from "@/components/global/TableExpand.vue"
import TableSelect from "@/components/global/table/TableSelect.vue"
import GroupTableSelect from "@/components/global/table/GroupTableSelect.vue"
import appCheckbox from "@/components/global/Checkbox.vue"
import sortingMixin from '@/mixins/table/sorting.mixin'

export default {
  name: 'GroupTable',

  mixins: [sortingMixin],

  props: {
    headers: Array,
    groupedList: null,
    value: Array,
    itemKey: {
      required: false
    },
    loading: Boolean,
    grouped: Boolean,
    list: Array,
    sortBy: Array,
    sortDesc: Array,
    expanded: Array,
    showSelect: { type: Boolean, default: true },
    disabledSelect: { type: Boolean, default: false },
    multiExpand: Boolean,
    disabled: Boolean,
    disabledText: {
      type: String,
      default: 'Select course'
    }
  },

  data: () => ({
    expandedItems: {},
    activeItems: {},
  }),

  computed: {
    items() {
      if (!this.grouped) return this.list
      if (!this.groupedList) return []
      let formattedGroupList = Object.values(this.groupedList)
      if (!formattedGroupList.length) return []
      return this.list
    },
    everyItem() {
      if (!this.value) return false
      if (!this.items.length) return false
      return this.value.length === this.items.length
    },
    headerProps() {
      return {
        everyItem: this.everyItem,
        headers: this._headers,
        options: {
          sortBy: this.sortBy,
          sortDesc: this.sortDesc
        }
      }
    },

    _headers() {
      let selectHeader = {text: '', sortable: false, value: 'data-table-select'}
      let headers = this.headers.map(item => item)
      if (this.showSelect) headers.unshift(selectHeader)
      return headers
    },

    on() {
      return {
        ['toggle-select-all']: this.toggleSelectAll,
        sort: this.sort
      }
    }
  },

  methods: {
    expand(key, item) {
      if (key in this.expandedItems) {
        this.hideExpandedItem(key)
        return
      }
      if (Object.keys(this.expandedItems).length && !this.multiExpand) this.hideExpandedItem(Object.keys(this.expandedItems)[0])
      this.$set(this.expandedItems, key, item)
      this.$emit('update:expanded', this._expanded())
    },

    hideExpandedItem(key) {
      this.$delete(this.expandedItems, key)
      this.$emit('update:expanded', this._expanded())
    },

    _isExpanded(index) {
      return index in this.expandedItems;
    },

    toggleSelectAll(val) {
      if (!val) return this.$emit('input', [])
      this.$emit('input', this.items)
    },

    selectItem(item) {
      this.$emit('input', [...this.value, item])
    },

    unselectItem(item) {
      let unselectedItemIndex = this.value.findIndex(activeItem => activeItem[this.itemKey] === item[this.itemKey])
      if (unselectedItemIndex < 0) return
      let activeItems = [...this.value]
      activeItems.splice(unselectedItemIndex, 1)
      this.$emit('input', activeItems)
    },

    _expanded() {
      return Object.values(this.expandedItems)
    }
  },

  watch: {
    value: {
      handler: function () {
        if (!this.itemKey || !this.showSelect) return
        for (let field in this.activeItems) {
          if (!this.value.length) {
            this.$delete(this.activeItems, field)
            continue
          }
          let item = this.value.find(item => item[this.itemKey] === field)
          if (!item) this.$delete(this.activeItems, field)
        }
        this.value.forEach((item, index) => {
          let key = this.itemKey ? item[this.itemKey] : index
          if (key in item) return
          this.$set(this.activeItems, key, item)
        })
      },
      immediate: true
    }
  },

  components: {
    expanded: Expanded,
    tableSelect: TableSelect,
    groupTableSelect: GroupTableSelect,
    appCheckbox: appCheckbox,
  }
}
</script>

<style lang="scss" scoped>
.group-row {
  background-color: $primary-color !important;
  color: #fff;

  &:hover {
    background-color: $primary-color;

    & td {
      background-color: $primary-color;
    }
  }

  &.noItems {
    display: none;
  }
}

.preloader {
  height: 4px;
}
</style>

<style lang="scss">
.disabled {
  pointer-events: none !important;
  opacity: 0.5;
}

.group-row {
  .v-input--checkbox .v-icon {
    color: #fff;
  }
}

.v-data-table-header tr {
  th:first-of-type {
    padding-left: 16px;
  }

  th:last-of-type {
    padding-right: 16px;
  }
}

.contents {
  display: contents;
  vertical-align: middle;
}

.contents:nth-child(2n) tr {
  background: #f1f2f6;
}

.no-data-available {
  text-align: center;
  padding: 20px 0 !important;
  background: #f1f2f6;
  font-family: $font-roboto;
  font-size: 12px !important;
  color: $label-color;
}
</style>
