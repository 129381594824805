<script>
export default {
  render() {
    return this.$scopedSlots.default({
      isSelected: this.isSelected,
      select: this.select
    })
  },

  props: {
    item: Object,
    activeItems: Object,
    groupedItems: Array,
    itemKey: {
      required: true
    },
    items: Array
  },

  computed: {
    isSelected() {
      if (!Object.keys(this.activeItems).length || !this.groupedItems.length) return false
      let isSelected = true
      this.items.forEach(item => {
        if (!isSelected) return
        if (item[this.itemKey] in this.activeItems) return
        isSelected = false
      })
      return isSelected
    }
  },

  methods: {
    select() {
      if (this.isSelected) {
        return this.unselect()
      }
      let activeItems = {...this.activeItems}
      this.items.forEach(item => {
        if (item[this.itemKey] in this.activeItems) return
        activeItems[item[this.itemKey]] = item
      })
      this.$emit('selected', Object.values(activeItems))
    },

    unselect() {
      let itemKeys = this.items.map(item => item[this.itemKey])
      let activeItems = {...this.activeItems}
      itemKeys.forEach(item => {
        delete activeItems[item]
      })
      return this.$emit('unselected', Object.values(activeItems))
    }
  }
}
</script>
