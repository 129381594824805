<script>
export default {
  render() {
    return this.$scopedSlots.default({
      expand: this._expand,
      isExpanded: this.isExpanded
    })
  },

  props: {
    expand: Function,
    index: Number,
    item: Object,
    expandedItems: Object,
    itemKey: {
      required: true
    }
  },

  data: () => ({

  }),

  methods: {
    _expand() {
      this.expand(this.item[this.itemKey], this.item)
    },

    select() {

    }
  },

  computed: {
    isExpanded() {
      if(this.item[this.itemKey] in this.expandedItems) return true
      return false
    }
  }
  
}
</script>